<template>
  <div>
    <div v-if="loading">
      Aguarde mais um momento, estamos quase lá...
      <br>
      <br>
      <br>
    </div>
    <b-overlay
      :show="loading || loadingPayment"
      rounded="sm"
    >
      <b-row>
        <b-col md="6">
          <b-card
            v-if="items.length > 0"
            class="mb-4"
          >
            <h4>Detalhes do pedido</h4>
            <b-card-text
              v-for="(item, index) in itemsState"
              :key="item.id"
            >
              {{ index + 1 }} - {{ item.name }}: R$: {{ item.value }} - x{{ item.amount }}
            </b-card-text>
            <b-card-text>
              Total: R$: {{ calcTotalValue(itemsState) }}
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>

      <validation-observer
        ref="refFormObserver"
      >
        <b-form
          class="list-view product-checkout"
          @submit.prevent="submit()"
        >
          <b-row>
            <b-col md="12">
              <b-row>
                <b-col
                  v-if="installmentsOptions && loading === false"
                  cols="12"
                >
                  <validation-provider
                    v-slot="{ errors }"
                    name="installments"
                    rules="required"
                  >
                    <b-form-group
                      label="Parcelas"
                      label-for="installments"
                    >
                      <b-form-select
                        id="installments"
                        v-model="installments"
                        :options="installmentsOptions"
                        :state="errors.length > 0 ? false : null"
                      />
                    </b-form-group>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-overlay>
    <b-button
      v-if="installmentsOptions && loading === false"
      block
      variant="success"
      style="margin: 5px 0 10px 0"
      :disabled="loadingPayment"
      @click="finishPayment()"
    >
      Finalizar pagamento
    </b-button>
  </div>
</template>

<script>
import {
  BCard, BCardText, BRow, BCol, BFormGroup, BFormInput,
} from 'bootstrap-vue'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { ValidationProvider, ValidationObserver, extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import PaymentServices from './paymentServices'
import { mapActions } from 'vuex'

extend('required', {
  ...required,
  message: 'Esse campo é obrigatório.',
})

export default {
  components: {
    BRow,
    BCol,

    BFormGroup,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      items: [],
      customer: {
        name: '',
        cpf: '',
        email: '',
        phone_number: '',
        birth: '',
        address: {
          street: '',
          number: '',
          neighborhood: '',
          zipcode: '',
          city: '',
          state: ''
        }
      },
      installments: 1,
      installmentsOptions: null,
      loading: true,
      loadingPayment: false,
    }
  },
  computed: {
    toGetInstallments() {
      return this.$store.state.payment.toGetInstallments
    },
    token() {
      return this.$store.state.payment.token
    },
    itemsState() {
      return this.$store.state.payment.items
    },
    customerState() {
      return this.$store.state.payment.customer
    },
    addressState() {
      return this.$store.state.payment.address
    },
  },
  async mounted() {
    await PaymentServices().startPayment()
    console.log('rodou src')
    await PaymentServices().getInstallments(this.toGetInstallments).then(res => {
      console.log(this.toGetInstallments)
      console.log(res)
      this.installmentsOptions = []
      res.data.installments.forEach(installment => {
        this.installmentsOptions.push({
          value: installment.installment,
          text: `${installment.installment} parcel. de R$ ${installment.currency}`
        })
      })
      this.installments = 1
      this.loading = false
    })

    this.customer.name = this.customerState.name
    this.customer.cpf = this.customerState.cpf
    this.customer.email = this.customerState.email
    this.customer.phone_number = this.formatPhoneNumber(this.customerState.phone_number)
    this.customer.birth = this.formatDate(this.customerState.birth, 'US')
    this.customer.address.street = this.addressState.street
    this.customer.address.number = this.addressState.number
    this.customer.address.neighborhood = this.addressState.neighborhood
    this.customer.address.zipcode = this.addressState.zipcode
    this.customer.address.city = this.addressState.city
    this.customer.address.state = this.addressState.state

    await this.itemsState.forEach(item => {
      this.items.push({
        name: item.name,
        amount: item.amount,
        value: item.value * 100
      })
    })
  },
  methods: {
    ...mapActions('payment', [
      'ActionSetItems',
      'ActionsSetToken',
      'ActionsSetCustomer',
      'ActionSetAddress',
      'ActionSetPayment'
    ]),
    calcTotalValue(array) {
      this.total = 0
      if (array.length > 0) {
        array.forEach(element => {
          this.total = (element.amount * element.value) + this.total
        })
      }

      return this.total
    },
    finishPayment() {
      this.loadingPayment = true
      PaymentServices().finishPayment({
        items: this.items,
        itemsToSave: this.itemsState,
        customer: this.customer,
        installments: this.installments,
        token: this.token
      }).then(res => {
        if (res.data.status === 200) {
          this.ActionsSetToken('')
          this.ActionsSetCustomer({
            name: '',
            cpf: '',
            email: '',
            phone_number: '',
            birth: '',
          })
          this.ActionSetAddress({
            street: '',
            number: '',
            neighborhood: '',
            zipcode: '',
            city: '',
            state: '',
          })
          this.ActionSetPayment(null)
          this.ActionSetItems([])
          const arr = []
          localStorage.setItem('items', JSON.stringify(arr))

          this.$swal({
            title: res.data.message,
            text: 'O item será liberado automaticamente após a confirmação do pagamento!  ',
            icon: 'success',
            confirmButtonColor: '#3085d6',
            confirmButtonText: 'Ok',
          }).then(() => {
            window.location.href = '/congressos'
          })
        } else if (res.data.status === 499) {
          this.loading = false

          this.$router.go(-1)

          this.$toast({
            component: ToastificationContent,
            props: {
              icon: 'InfoIcon',
              title: res.data.message,
              text: res.data.errors[2],
              variant: 'danger',
            }
          })
        }
      })
    },
    // eslint-disable-next-line consistent-return
    formatDate(date, toLang) {
      const dateDefault = date

      if (date) {
        if (toLang === 'US') {
          const XXI = dateDefault.split('/')
          const dateUS = `${XXI[2]}-${XXI[1]}-${XXI[0]}`
          return dateUS
        } if (toLang === 'PT') {
          const XXI = dateDefault.split('-')
          const datePT = `${XXI[2]}-${XXI[1]}-${XXI[0]}`
          return datePT
        }
      }
    },
    formatPhoneNumber(number) {
      if (number.indexOf(' ') >= 0) {
        const phoneNumber = number.split(' ')
        console.log(`${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]}`)
        return `${phoneNumber[1]}${phoneNumber[2]}${phoneNumber[3]}`
      }
      return number
    },
  }
}
</script>

<style>

</style>
